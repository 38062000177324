import { Box, Typography, Grid, Link } from '@mui/material';
import React from 'react';
import CopyrightComponent from '../Copyright/CopyrightComponent';
import SharmalLogo from '../../assets/images/SharmalWithUrl.png';
import CallIcon from '../../assets/icons/CallIcon.png';
import EmailIcon from '../../assets/icons/EmailIcon.png';
import LocationIcon from '../../assets/icons/LocationIcon.png';
import FacebookIcon from '../../assets/icons/FacebookIcon.png';
import ViberIcon from '../../assets/icons/ViberIcon.png';

const FooterComponent = () => {
  return (
    <>
      <Box sx={{
        background: 'linear-gradient(183deg, rgba(224,222,247,1) 24%, rgba(255,255,255,1) 100%)',
        padding: '50px 0 0 0',
      }}>
        <Grid container spacing={4} justifyContent="center" marginBottom="20px">
          {/* Left Side */}
          <Grid item xs={12} md={6} lg={5}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <img
                src={SharmalLogo}
                alt="logo"
                style={{ width: '170px', height: 'auto', marginBottom: '20px' }}
              />
              <Box sx={{ paddingLeft: '10px', display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                <Typography sx={{ fontSize: '16px', color: '#5B1144' }}>သင့်စိတ်ကူးထဲက</Typography>
                <Typography variant='h6' className='gradient-text'>အိမ်ခြံမြေနှင့်ကားများကို</Typography>
                <Typography sx={{ fontSize: '12px', marginTop: '10px', padding: '5px 0' }} className='gradient-text-another'>လွယ်ကူလျင်မြန်စွာရှာဖွေလိုက်ပါ</Typography>
              </Box>

              <Box sx={{ paddingLeft: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                  <img
                    src={CallIcon}
                    alt="CallIcon"
                    style={{ width: '24px', height: '24px', marginRight: '10px' }}
                  />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>09752733981 , 09752733985</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                  <img
                    src={EmailIcon}
                    alt="EmailIcon"
                    style={{ width: '24px', height: '24px', marginRight: '10px' }}
                  />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>valiant2542023@gmail.com</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={LocationIcon}
                    alt="LocationIcon"
                    style={{ width: '24px', height: '24px', marginRight: '10px' }}
                  />
                  <Typography sx={{ fontSize: '12px' }}>No.1217, 4th Floor, Pinlon Road, 35 Ward, North Dagon, Yangon.</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Side */}
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px'
              }}>
                <Box sx={{
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  border: '1px solid #0405053D',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <img src={FacebookIcon} alt="FacebookIcon" style={{ width: '53%', height: '56%' }} />
                </Box>
                <a href="viber://chat?number=959752733981">
                  <Box sx={{
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    border: '1px solid #0405053D',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer' // Adds a pointer cursor for better UX
                  }}>
                    <img src={ViberIcon} alt="ViberIcon" style={{ width: '53%', height: '56%' }} />
                  </Box>
                </a>
              </Box>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '40px',
                width: '100%',
                textAlign: 'center'
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Typography variant='h6' sx={{ color: '#5B1144', fontWeight: '700' }}>အိမ်ခြံမြေ</Typography>
                  <Typography onClick={() => window.location.href = '/property?State=ရောင်းရန်'} variant='body2' style={{ cursor: 'pointer' }}>အိမ်ခြံမြေ အရောင်း</Typography>
                  <Typography onClick={() => window.location.href = '/property?State=ငှားရန်'} variant='body2' style={{ cursor: 'pointer' }}>အိမ်ခြံမြေ အငှား</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Typography variant='h6' sx={{ color: '#5B1144', fontWeight: '700' }}>ကား</Typography>
                  <Typography onClick={() => window.location.href = '/car'} variant='body2' style={{ cursor: 'pointer' }}>ကား အရောင်း</Typography>
                  <Typography onClick={() => window.location.href = '/contact'} variant='body2' sx={{ color: '#5B1144', fontWeight: '700', cursor: 'pointer' }}>ဆက်သွယ်ရန်</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            backgroundColor: '#FBB96F',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '60px',
            padding: { xs: '0 20px', md: '0 50px', lg: '0 70px' },
          }}
        >
          {/* Copyright Component */}
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Powered By{' - '}
            <Link
              href="https://www.nksoftwarehouse.com"
              target="_blank"
              rel="noopener"
              underline="none"
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                '&:hover': {
                  textDecoration: 'underline',
                },
                marginRight: 1,
              }}
            >
              NK Software House
            </Link>
            {' | '}
            <Link
              href="https://facebook.com/nksoftwareshouse"
              target="_blank"
              rel="noopener"
              underline="none"
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                '&:hover': {
                  textDecoration: 'underline',
                },
                marginLeft: 1,
              }}
            >
              Facebook
            </Link>
          </Typography>


          {/* Centered Text */}
          <CopyrightComponent />

          {/* Right Section for Additional Links */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
              maxWidth: '400px',
            }}
          >
            {/* Future Terms, Privacy, Cookies Links */}

            <Typography variant='body2'>Terms of Service</Typography>
            <Typography variant='body2'>Privacy Policy</Typography>
            <Typography variant='body2'>Cookies</Typography>

          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FooterComponent;
